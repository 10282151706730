/* Color palette */
:root {
  --raisin-black: #272932;
  --steel-blue: #4d7ea8;
  --baby-powder: #f9f7f3;
  --medium-champagne: #eddea4;
  --atomic-tangerine: #f7a072;
}

/* Sitewide theme */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Sections */
.hero {
  color: var(--baby-powder);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero h1 {
  font-size: 4rem;
}

.section {
  min-height: calc(100vh - 3.5rem);
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.section h1 {
  padding-bottom: 2rem;
}

.section-steel-blue {
  background-color: var(--steel-blue);
  color: white;
}

.section-baby-powder {
  background-color: var(--baby-powder);
}

.section-atomic-orange {
  background-color: var(--atomic-tangerine);
}

.section-raisin-black {
  background-color: var(--raisin-black);
}

/* Buttons */
.btn-atomic-tangerine {
  color: var(--raisin-black);
  background-color: var(--atomic-tangerine);
  border-color: var(--atomic-tangerine);
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 50px;
  box-shadow: 10px 10px 12px 1px rgb(0 0 0 / 20%);
}

.btn-atomic-tangerine:hover {
  color: white;
  box-shadow: none;
}

.btn-steel-blue {
  color: var(--raisin-black);
  background-color: var(--steel-blue);
  border-color: var(--steel-blue);
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 50px;
  box-shadow: 10px 10px 12px 1px rgb(0 0 0 / 20%);
}

.btn-steel-blue:hover {
  color: var(--baby-powder);
  box-shadow: none;
}

/* Offcanvas */
.offcanvas {
  max-width: 80vw;
  background-color: var(--raisin-black);
}

.offcanvas-header {
  display: block;
  text-align: right;
}

.btn-close:active {
  border-color: var(--steel-blue);
}

.offcanvas-body {
  align-items: right;
}

.nav-link {
  color: var(--atomic-tangerine);
  text-align: right;
  font-size: 1.5rem;
}

.nav-link:hover {
  color: var(--steel-blue);
}
