.about-section {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../Images/paris.jpg') center/cover no-repeat fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  position: relative;
}

.card.company-card {
  color: black;
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);
  border-radius: 2em;
  transition: transform 0.2s ease;
}

@media screen and (min-width: 768px) {
  .card.company-card:hover {
    transform: scale(1.1);
  }
}

.company-card-img-container {
  padding: 2rem;
  background-color: white;
  margin: 1rem;
}

.company-card-img {
  object-fit: contain;
  height: 8rem;
}

.company-card-button-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-top: auto;
  margin-bottom: 1rem;
}

.row-custom {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  margin-bottom: 2rem;
}
