/* Navbar */
.bg-raisin-black {
  background-color: var(--raisin-black);
}

.bg-transparent {
  background-color: transparent;
}

.navbar-container {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-brand {
  font-size: 1.5rem;
}

.navbar-toggler {
  margin-left: auto;
}
